import React, { useContext } from "react";
import Context from "../../_context";
import Link from "../../_components/link";
import styled from "styled-components";

const Nav = styled.span`
  text-decoration: none;
  &:hover {
    color: #fff;
  }
  &:visited {
    color: #fff;
  }
`;
const LogoImg = styled.img`
  max-width: 100px;
  //max-height: 100px;
  position: relative;
  z-index: -1;
  @media (min-width: 768px) {
    max-width: 200px;
  }
`;
const LogoText = styled.span`
  font-size: 1.5rem;
`;

export default ({ dark }) => {
  const state = useContext(Context);
  const builderId = useContext(Context).builderId;
  return (
    <Link to={`/`}>
      <Nav title='Inicio'>
        {state.main.logo.isImage ? (
          <LogoImg src={state.main.logo.value} />
        ) : (
          <LogoText>{state.main.logo.value}</LogoText>
        )}
      </Nav>
    </Link>
  );
};
